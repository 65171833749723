import ReadOnlyModeToggle from "@/components/ReadOnlyModeToggle";
import SearchFilter from "@/components/SearchFilter";
import { FullInput } from "@/components/Utils";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { UnreadsContext } from "@/models/UnreadsContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Add } from "@mui/icons-material";
import CancelRounded from "@mui/icons-material/CancelRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  List,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useLiveQuery } from "electric-sql/react";
import Fuse from "fuse.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import WorkspaceChannelGroup from "./WorkspaceChannelGroup";
import WorkspaceChannelListItem from "./WorkspaceChannelListItem";

interface WorkspaceFeedItem {
  id: string;
  title: string;
  latestActivity: string;
  joined: boolean;
  isPrivate?: boolean;
  feedGroupId?: string;
}

export interface WorkspaceFeedGroup {
  id: string;
  workspaceId: string;
  joined: boolean;
  label: string;
  feeds: WorkspaceFeedItem[];
  allFeeds: WorkspaceFeedItem[];
  joinedFeeds: WorkspaceFeedItem[];
}

export default function WorkspaceChannelList({
  notificationsBanner,
  handsFreeToggle,
}: { notificationsBanner: boolean; handsFreeToggle: boolean }) {
  const { toggleChannelModal, userReadOnlyMode, setWorkspaceModalOpen } =
    useContext(UxContext);
  const { accountEvent } = useContext(ActionContext);
  const { myAccountId, workspaceMemberships, isWorkspaceLimitedMember } =
    useContext(DataContext);
  const theme = useTheme();
  const [reRender, setReRender] = useState<boolean>(false);
  const channelListRef = useRef(null);
  const { allowNewWorkspaceCreation, readOnlyMode } = useFlags();
  const { db } = useElectric();
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const hasWorkspaces = workspaceMemberships?.length > 0;
  useEffect(() => {
    if (!myAccountId || hasWorkspaces || allowNewWorkspaceCreation === false) {
      return;
    }
    setWorkspaceModalOpen(true);
  }, [
    myAccountId,
    hasWorkspaces,
    setWorkspaceModalOpen,
    allowNewWorkspaceCreation,
  ]);

  const { results: myMembership } = useLiveQuery(() => {
    if (!myAccountId || !workspaceId) return;
    return db.workspace_membership.liveFirst({
      where: {
        workspaceId: workspaceId,
        accountId: myAccountId,
        status: "active",
      },
    });
  }, [myAccountId, workspaceId]);

  const limitedMember = isWorkspaceLimitedMember(workspaceId, myAccountId);
  // parent container height minus the height of the drawer header and nav controls, notifications banner and hands free toggle
  const dynamicHeight = () => {
    let offset = 320;
    if (notificationsBanner && !handsFreeToggle) {
      offset = 409;
    } else if (!notificationsBanner && handsFreeToggle) {
      offset = 405;
    } else if (notificationsBanner && handsFreeToggle) {
      offset = 494;
    }
    if (readOnlyMode) {
      offset += 18;
    }
    return `calc(100dvh - ${offset}px)`;
  };
  const [channelOrder, saveChannelOrder] = useLocalStorage(
    "channel-sort-order",
    "recent",
  );
  const [channelType, saveChannelType] = useLocalStorage(
    "channel-sort-type",
    "my-channels",
  );
  const [searchInput, setSearchInput] = useState<string>("");

  const { results: feedGroups } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.feed_group.liveMany({
      where: {
        workspaceId: workspaceId,
      },
    });
  }, [workspaceId]);

  const { results: allFeeds, error: channelError } = useLiveQuery(() => {
    if (!workspaceId || !myMembership?.id) return;

    const selectPortion = `
		SELECT
				feed.id,
				feed.title,
				feed.workspaceId,
				feed.*,
				feed.title as alpha_sort,
        feed_group_membership.groupId as feedGroupId,
				item.createdAt,
        permission.workspace_membershipId as joined,
				MAX(
					coalesce(feed.updatedAt, '0'),
					coalesce(MAX(item.createdAt), '0')
				) as latestActivity
			FROM feed
    `;

    let sqlQuery = "";
    if (!limitedMember && channelType === "all") {
      // ALL
      sqlQuery = `
          ${selectPortion}
          LEFT JOIN item 
            ON feed.id = item.feedId
          LEFT JOIN permission 
            ON feed.id = permission.feedId
            AND permission.workspace_membershipId = $1
            AND permission.name = 'read'
            AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          WHERE
            feed.workspaceId = $2
            AND isDm = 0 
            AND ((
              feed.isPrivate = 1
              AND permission.enabled = true
            ) OR (
              feed.isPrivate = 0
            ))
          GROUP BY feed.id
          ORDER BY latestActivity DESC
        `;
    } else if (channelType === "unread") {
      // MY UNREAD
      sqlQuery = `
          ${selectPortion}
          JOIN item 
            ON feed.id = item.feedId
            AND item.unread = 1
          JOIN permission 
            ON feed.id = permission.feedId
            AND permission.workspace_membershipId = $1
            AND permission.name = 'read'
            AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          WHERE
            feed.workspaceId = $2
            AND isDm = 0 
          GROUP BY feed.id
          ORDER BY latestActivity DESC
        `;
    } else {
      // MY CHANNELS
      sqlQuery = `
          ${selectPortion}
          LEFT JOIN item 
            ON feed.id = item.feedId
          JOIN permission 
            ON feed.id = permission.feedId
            AND permission.workspace_membershipId = $1
            AND permission.name = 'read'
            AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          WHERE
            feed.workspaceId = $2
            AND isDm = 0 
          GROUP BY feed.id
          ORDER BY latestActivity DESC
        `;
    }

    return db.liveRaw({
      sql: sqlQuery,
      args: [myMembership?.id, workspaceId],
    });
  }, [
    workspaceId,
    myMembership?.id,
    reRender,
    searchInput,
    channelType,
    limitedMember,
  ]) as {
    results: WorkspaceFeedItem[];
    error: any;
  };

  if (channelError) {
    // console.error(channelError);
  }

  const [debouncedValue] = useDebounce(searchInput, 500);

  const mappedFeedGroups = useMemo(() => {
    const allFeedsByGroupId = allFeeds?.reduce((acc, feed) => {
      if (!feed.feedGroupId) return acc;
      if (!acc[feed.feedGroupId]) {
        acc[feed.feedGroupId] = [];
      }
      acc[feed.feedGroupId].push(feed);
      return acc;
    }, []);

    return feedGroups
      ?.map((group) => {
        const feeds = allFeedsByGroupId?.[group.id];
        return {
          id: group.id,
          workspaceId: group.workspaceId,
          label: group.name,
          feeds,
          allFeeds: feeds,
          joinedFeeds: feeds?.filter((feed) => feed.joined),
          joined: feeds?.some((feed) => feed.joined),
        } as WorkspaceFeedGroup;
      })
      .filter((group) => group.feeds?.length > 0);
  }, [allFeeds, feedGroups]);

  const filteredWorkspaceFeedGroups = useMemo(() => {
    console.log("filteredWorkspaceFeedGroups");

    const groupFuse = new Fuse(mappedFeedGroups, {
      useExtendedSearch: true,
      includeMatches: true,
      keys: ["label", "id", "feeds.title", "feeds.id"],
    });

    if (debouncedValue) {
      return groupFuse
        .search(`'${debouncedValue}`)
        ?.map((result) => {
          return {
            ...result.item,
            feeds: result.item.feeds?.filter((feed) =>
              result.matches.some(
                (match) =>
                  (match.key === "feeds.title" || match.key === "feeds.id") &&
                  (match.value === feed?.title || match.value === feed?.id),
              ),
            ),
          };
        })
        ?.sort((a, b) => a?.label?.localeCompare(b?.label));
    }
    return mappedFeedGroups
      ?.map((group) => ({
        ...group,
        feeds: group.feeds?.sort((a, b) => {
          if (channelOrder === "recent") {
            console.log(
              "channelOrder recent",
              b.latestActivity,
              a.latestActivity,
            );
            console.log(b.latestActivity.localeCompare(a.latestActivity));
            return b.latestActivity.localeCompare(a.latestActivity);
          }
          return a?.title?.localeCompare(b?.title);
        }),
      }))
      .sort((a, b) => {
        if (channelOrder === "recent") {
          // Sort groups by the recent activity of their first feed
          return b.feeds[0]?.latestActivity.localeCompare(
            a.feeds[0]?.latestActivity,
          );
        }
        return a?.label?.localeCompare(b?.label); // Fallback to alphabetical sort
      });
  }, [debouncedValue, mappedFeedGroups, channelOrder]);

  const filteredWorkspaceFeeds = useMemo(() => {
    const fuse = new Fuse(allFeeds, {
      useExtendedSearch: true,
      keys: ["title", "id"],
    });

    let filteredFeeds = allFeeds;
    if (debouncedValue) {
      filteredFeeds = fuse
        .search(`'${debouncedValue}`)
        ?.map((result) => result.item)
        ?.sort((a, b) => a?.title?.localeCompare(b?.title));
    } else {
      filteredFeeds = allFeeds?.sort((a, b) => {
        if (channelOrder === "recent") {
          return b.latestActivity.localeCompare(a.latestActivity);
        }
        return a?.title?.localeCompare(b?.title);
      });
    }
    return filteredFeeds?.filter((feed) => feed?.feedGroupId === null);
  }, [debouncedValue, allFeeds, channelOrder]);

  const hasChannels =
    filteredWorkspaceFeeds?.length > 0 ||
    filteredWorkspaceFeedGroups?.length > 0;

  const reRenderList = () => {
    setReRender((current) => !current);
  };

  const resetScroll = () => {
    if (channelListRef.current) {
      channelListRef.current.scrollTop = 0;
    }
  };

  const handleChannelTypeChange = (value) => {
    resetScroll();
    saveChannelType(value);
  };

  const handleChannelOrderChange = (value) => {
    resetScroll();
    saveChannelOrder(value);
  };

  const handleLeftChannel = () => {
    if (workspaceId && allFeeds && allFeeds?.length > 0) {
      // after leaving a channel, redirect to the first feed in the list
      // removed due to double navigates
      // navigate(
      //   `/workspaces/${workspaceId}/feeds/${allFeeds[0]?.id}?refresh=true`,
      // );
    }
  };

  const markAllChannelsAsRead = async (feeds) => {
    for (const feed of feeds) {
      if (!feed.joined) continue;
      accountEvent("Marked Feed as Read", {
        feedId: feed.id,
      });
    }
  };

  return (
    <Stack
      sx={{
        height: "calc(100% - 4px)",
        gap: 2,
        borderRadius: "1rem",
        transition: "all 0.5s ease-out",
        border: userReadOnlyMode
          ? `solid 2px ${theme.palette.brand.primary.main}`
          : "solid 2px transparent",
      }}
    >
      <Box>
        {hasWorkspaces ? (
          <>
            <Box
              sx={{
                background: theme.palette.secondary.dark,
                borderRadius: 4,
                pl: 2,
              }}
            >
              <ReadOnlyModeToggle sx={{ height: "30px" }} />

              <Box
                key={`${workspaceId}-channel-controls`}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pt: readOnlyMode ? 0 : 2.5,
                  pr: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    gap: 1.5,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.25rem",
                    height: 44,
                  }}
                >
                  Channels
                </Box>

                {!limitedMember && !searchInput ? (
                  <IconButton
                    onClick={toggleChannelModal}
                    aria-label={Locator.workspaceNav.channels.create}
                    sx={{ borderRadius: 4, width: 48, height: 48 }}
                  >
                    <Add />
                  </IconButton>
                ) : null}
                <SearchFilter
                  changeChannelType={(value) => handleChannelTypeChange(value)}
                  changeChannelSortOrder={(value) =>
                    handleChannelOrderChange(value)
                  }
                />
              </Box>
              <Box sx={{ pt: 0.5, pb: 1, pr: 2 }}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "1rem",
                    backgroundColor: alpha(theme.palette.common.white, 0.15),
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.common.white, 0.25),
                    },
                    marginLeft: 0,
                    width: "100%",
                    flexGrow: 1,
                  }}
                >
                  {searchInput?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        zIndex: 10,
                      }}
                    >
                      <Button
                        aria-label={Locator.workspaceNav.channels.find.clear}
                        sx={{
                          p: 0,
                          width: "auto",
                          minWidth: "auto",
                          "&:hover": {
                            background: "transparent !important",
                          },
                        }}
                        onClick={() => setSearchInput(() => "")}
                      >
                        <CancelRounded />
                      </Button>
                    </Box>
                  )}
                  <FullInput
                    id="channel-search"
                    icon={
                      <Box
                        sx={{
                          position: "absolute",
                          left: "0.5rem",
                          display: "flex",
                          top: "0.775rem",
                          zIndex: 12,
                        }}
                      >
                        <SearchIcon sx={{ fontSize: "1.25rem" }} />
                      </Box>
                    }
                    value={searchInput}
                    onChange={(e) => setSearchInput(() => e.target.value)}
                    placeholder="Find a channel"
                    inputProps={{
                      "aria-label": "search",
                      sx: {
                        "&.MuiInputBase-input": {
                          borderRadius: 4,
                          border: `2px solid ${theme.palette.secondary.main}`,
                          minHeight: 48,
                          py: "10px",
                          background: searchInput
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.dark,
                          "&:focus": {
                            borderColor: theme.palette.neutral.light,
                          },
                          "&:focus-visible": {
                            outline: "none",
                            boxShadow: "none",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  height: dynamicHeight(),
                  pr: 0.75,
                }}
                className="scrollable-content"
                ref={channelListRef}
              >
                <List
                  key={`${workspaceId}-channels`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                    py: 0,
                  }}
                  aria-label={Locator.workspaceNav.channels.list.container}
                >
                  {filteredWorkspaceFeedGroups?.length > 0 &&
                    filteredWorkspaceFeedGroups?.map((group, index) => (
                      <WorkspaceChannelGroup
                        feedGroup={group}
                        joined={group?.joined}
                        membershipId={myMembership?.id}
                        key={group?.id}
                        markAllChannelsAsRead={() =>
                          markAllChannelsAsRead(group?.feeds)
                        }
                        reRenderList={reRenderList}
                      />
                    ))}
                  {filteredWorkspaceFeeds?.length > 0
                    ? filteredWorkspaceFeeds?.map((feed, index) => (
                      <WorkspaceChannelListItem
                        markAllChannelsAsRead={() =>
                          markAllChannelsAsRead(filteredWorkspaceFeeds)
                        }
                        setJoined={() => reRenderList()}
                        joined={feed.joined}
                        feed={feed}
                        membershipId={myMembership?.id}
                        feedId={feed?.id}
                        key={feed?.id}
                      />
                    ))
                    : null}
                  {!hasChannels && (searchInput || channelType === "unread") ? (
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography sx={{ fontWeight: 500 }}>
                        No channels or groups matched your query
                      </Typography>
                    </Stack>
                  ) : null}
                </List>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: theme.palette.secondary.dark,
              color: theme.palette.neutral.main,
              borderRadius: 4,
              height: "100%",
            }}
          >
            No Channels Available
          </Box>
        )}
      </Box>
    </Stack>
  );
}
