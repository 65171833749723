import { Ampli } from "@/ampli";
import { upsertMyAccount } from "@/data/oldWorld";
import {
  createManyWorkspace,
  createManyWorkspaceMembership,
  createManyWsAccount,
  upsertDeviceRegistration,
  upsertDirectWsInvitation,
  upsertManyWsPermission,
  upsertWorkspace,
  upsertWorkspaceMembership,
  upsertWsAccount,
  upsertWsAudioEncoding,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsCallRecord,
  upsertWsCommandAlias,
  upsertWsDisplayArtifact,
  upsertWsDraft,
  upsertWsEvent,
  upsertWsFeed,
  upsertWsFeedGroup,
  upsertWsFeedGroupMembership,
  upsertWsFile,
  upsertWsHandsFreeStatus,
  upsertWsItem,
  upsertWsLink,
  upsertWsPAM,
  upsertWsPermission,
  upsertWsPublishedDraft,
  upsertWsScheduleTrigger,
  upsertWsTemplate,
  upsertWsTranscription,
} from "@/data/workspace";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { useElectric } from "@/electric/ElectricWrapper";
import {
  Account,
  DirectWsInvitation,
  Electric,
  Feed,
  Permission,
  WorkspaceMembership,
} from "@/generated/client";
import UseStorage from "@/hooks/useStorage";
import BootstrapApplication from "@/models/BootstrapApplication";
import { type MappedWorkspaceRole, buildVersion } from "@/utils";
import * as Sentry from "@sentry/browser";
import { useNetworkState } from "@uidotdev/usehooks";
import { useLiveQuery } from "electric-sql/react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppContext as ApplicationContext,
  DeviceContext,
  DeviceRegistration,
  UserInfoResponse,
  Workspace,
  WsAppSyncEvent,
  WsAudioEncoding,
  WsEvent,
  WsItem,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { AppContext } from "./AppStateProvider";
import { AudioAppContext } from "./AudioAppContextProvider";
import { TrackingContext } from "./TrackingStateProvider";
import { UnreadsContext } from "./UnreadsContextProvider";
import accountInfo, { AccountInfo } from "./accountInfo";
import {
  downloadPaginatedBootstrapFeedItems,
  getNextFeedItemPage,
  initialFeedLoad,
} from "./actions/initialFeedLoad";
import { LANGUAGE_LIST, PreferredLanguage } from "./languages";
import { useLocalStorage } from "@uidotdev/usehooks";
import cuid from "cuid";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const ITEMS_PER_PAGE = 10;
let subscription: any;

const LANGUAGE_SESSION_KEY = "preferredLanguage";
const LANGUAGE_DEFAULT_VALUE = LANGUAGE_LIST[0] ?? "none";

function appSyncSubscriptionUpdate(
  db: Electric["db"],
  event: WsAppSyncEvent,
  addToAudioQueue: (audioEncodings: WsAudioEncoding[]) => Promise<void>,
  handleUnreadItem: (items: WsItem) => Promise<void>,
  handleReadItemEvent: (events: WsEvent) => Promise<void>,
  client: Client,
) {
  console.log("app sync", event);
  for (const i of event?.accounts || []) {
    upsertWsAccount(db, i);
  }
  for (const i of event?.workspaces || []) {
    upsertWorkspace(db, i);
  }
  for (const i of event?.workspaceMemberships || []) {
    upsertWorkspaceMembership(db, i);
  }
  for (const i of event?.directWorkspaceInvitations || []) {
    upsertDirectWsInvitation(db, i);
  }
  for (const i of event?.feeds || []) {
    upsertWsFeed(db, i);
  }
  for (const i of event?.feedGroups || []) {
    upsertWsFeedGroup(db, i);
  }
  for (const i of event?.feedGroupMemberships || []) {
    upsertWsFeedGroupMembership(db, i);
  }
  for (const i of event?.permissions || []) {
    upsertWsPermission(db, i);
  }
  for (const i of event?.items || []) {
    upsertWsItem(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "item",
    });
  }
  for (const i of event?.audioEncodings || []) {
    upsertWsAudioEncoding(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "audioEncoding",
    });
  }
  for (const i of event?.displayArtifacts || []) {
    upsertWsDisplayArtifact(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "displayArtifact",
    });
  }
  for (const i of event?.files || []) {
    upsertWsFile(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "file",
    });
  }
  for (const i of event?.links || []) {
    upsertWsLink(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "link",
    });
  }
  for (const i of event?.transcriptions || []) {
    upsertWsTranscription(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "transcription",
    });
  }
  for (const i of event?.callRecords || []) {
    upsertWsCallRecord(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "callRecord",
    });
  }
  for (const i of event?.events || []) {
    upsertWsEvent(db, i);
    if (i.contentId) {
      // client.createContentReceipt({
      // 	contentId: i.contentId,
      // 	artifactId: i.id,
      // 	artifactType: "accountEvent",
      // });
    }
  }
  for (const i of event?.commandAliases || []) {
    upsertWsCommandAlias(db, i);
  }

  for (const i of event?.workflowItems || []) {
    upsertWsDraft(db, i);
  }

  for (const i of event?.scheduleTriggers || []) {
    upsertWsScheduleTrigger(db, i);
  }

  for (const i of event?.broadcastActions || []) {
    upsertWsBroadcastAction(db, i);
  }

  for (const i of event?.publishedWorkflowItems || []) {
    upsertWsPublishedDraft(db, i);
  }

  for (const i of event?.broadcastRecipients || []) {
    upsertWsBroadcastRecipient(db, i);
  }
  for (const i of event?.pam || []) {
    upsertWsPAM(db, i);
  }
  for (const i of event?.templates || []) {
    upsertWsTemplate(db, i);
  }
  for (const i of event?.deviceRegistrations || []) {
    upsertDeviceRegistration(db, i);
  }

  if (event?.items?.length > 0) {
    for (const i of event.items) {
      handleUnreadItem(i);
    }
  }
  if (event?.events?.length > 0) {
    for (const e of event.events) {
      handleReadItemEvent(e);
    }
  }
  addToAudioQueue(event.audioEncodings);
  // updateUnreadItems(db, event.events);
}

export type DataState = {
  appContext?: ApplicationContext;
  availableWorkspaceRoles?: Map<string, MappedWorkspaceRole>;
  getWorkspaceRoleLabel?: (role: string) => string;
  myAccount?: Account;
  myAccountId?: string;
  myCurrentWorkspaceRole?: WorkspaceMembership["role"];
  myCurrentWorkspaceMembership?: WorkspaceMembership;
  currentFeedId?: string;
  currentWorkspaceId?: string;
  currentFeed?: Feed;
  currentFeedAccounts?: Map<string, AccountInfo>;
  currentFeedPendingInvites?: DirectWsInvitation[];
  deviceContext?: DeviceContext;
  isCurrentFeedAdmin?: boolean;
  haveWritePermission?: boolean;
  workspaceMemberships?: WorkspaceMembership[];
  bootstrapComplete?: boolean;
  loadFeed?: (feedId: string) => Promise<boolean>;
  loadNextFeedItemPage?: (feedId: string) => Promise<number>;
  loadWorkspaceDetails?: (workspaceId: string) => void;
  loadWorkspaceWorkflowItems?: (workspaceId: string) => void;
  preferredLanguage: PreferredLanguage;
  setPreferredLanguage?: (language: PreferredLanguage) => void;
  fetchWorkspaceMembership?: (
    workspaceMembershipId: string,
    feedId: string,
  ) => Promise<WorkspaceMembership>;
  listFeedPermissions?: (
    workspaceMembershipId: string,
    feedId: string,
  ) => Promise<Array<Permission>>;
  checkFeedPermissions?: ({
    workspaceMembershipId,
    feedId,
    isUserOnPage,
    workspaceId,
  }: {
    workspaceMembershipId: string;
    workspaceId: string;
    feedId: string;
    isUserOnPage: boolean;
  }) => Promise<boolean>;
  joinPublicChannel?: (workspaceId: string, feedId: string) => Promise<boolean>;
  leavePublicChannel?: (
    workspaceId: string,
    feedId: string,
    membershipId: string,
  ) => Promise<boolean>;
  joinFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
  }) => Promise<boolean>;
  leaveFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
  }) => Promise<boolean>;
  fetchWorkspaceHandsFreeStatus?: () => Promise<void>;
  fetchGeoCoords?: (
    workspaceId: string,
    membershipIds: string[],
  ) => Promise<{ lat: number; lng: number } | null>;
  fetchSingleFeed?: (feedId: string) => Feed | null;
  isWorkspaceAdmin?: (workspaceId: string, accountId: string) => boolean;
  isWorkspaceLimitedMember?: (
    workspaceId: string,
    accountId: string,
  ) => boolean;
  fetchMyActiveFeeds?: (
    workspaceId: string,
    workspaceMembershipId: string,
  ) => Array<{
    id: string;
    name: string;
    title: string;
    updatedAt: string;
    workspaceId: string;
    latestActivity: string;
  }>;
  workspaces?: Workspace[];
};

//create a context, with createContext api
export const DataContext = createContext<DataState>({
  preferredLanguage: "en",
});

type Props = {
  children: React.ReactNode | React.ReactNode[];
  client: Client;
};

const DataProvider = ({ children, client }: Props) => {
  const { pubSub, flags } = React.useContext(AppContext);
  const { addToAudioQueue } = React.useContext(AudioAppContext);
  const { handleUnreadItem, handleReadItemEvent } =
    React.useContext(UnreadsContext);
  const { limitedMemberRole } = useFlags();
  const ldClient = useLDClient();

  // not sure if this is the best idea? gonna try it though.
  const params = useParams();
  const { feedId, workspaceId, itemId } = params;
  const currentFeedId = feedId;
  const currentWorkspaceId = workspaceId;

  const [bootstrapComplete, setBootstrapComplete] = useState<boolean>(false);
  const [boostrapLoading, setBoostrapLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserInfoResponse | null>(null);

  const { getLocalStorage, setLocalStorage } = UseStorage();
  const { ampli }: { ampli: Ampli } = React.useContext(TrackingContext);

  const { db, adapter } = useElectric();

  const { results: myAccount } = useLiveQuery(
    db.account.liveFirst({
      where: {
        mine: 1,
      },
    }),
  );

  const localStoragePreferredLanguage = getLocalStorage(
    "preferredLanguage",
  ) as PreferredLanguage;

  const preferredLanguage =
    localStoragePreferredLanguage || LANGUAGE_DEFAULT_VALUE;

  const setPreferredLanguage = useCallback(
    (language: PreferredLanguage) => {
      if (!myAccount?.id) return;
      db.account
        .update({
          where: {
            id: myAccount.id,
          },
          data: {
            preferredLanguage: language,
          },
        })
        .then(() => {
          setLocalStorage({ key: LANGUAGE_SESSION_KEY, value: language });
        });
    },
    [db, myAccount?.id, setLocalStorage],
  );
  // setup mapped workspace roles from api -> client label
  const availableWorkspaceRoles = new Map<string, MappedWorkspaceRole>([
    ["admin", { role: "admin", label: "Administrator", enabled: true }],
    ["member", { role: "member", label: "Organizer", enabled: true }],
    [
      "limitedMember",
      {
        role: "limitedMember",
        label: "Member",
        enabled: limitedMemberRole,
      },
    ],
  ]);

  const getWorkspaceRoleLabel = (role: string) => {
    return availableWorkspaceRoles?.get(role)?.label || role;
  };

  const { results: workspaces } = useLiveQuery(
    db.workspace.liveMany({ orderBy: { name: "asc" } }),
  );

  const { results: workspaceMemberships } = useLiveQuery(
    db.workspace_membership.liveMany({
      where: {
        accountId: myAccount?.id,
        status: "active",
      },
    }),
  );

  const subscribeToAppSync = React.useCallback(
    (accountIds: string[]) => {
      if (!pubSub || !client) return;

      const channels: string[] = [];
      for (const accountId of accountIds) {
        channels.push(`wsaccount#${accountId}`);
      }
      console.log("Subscribing to ws account feed", channels);
      const newSub = pubSub.subscribeFilter(
        channels,
        (data?: any) => {
          if (data?.data) {
            const eventData = JSON.parse(data.data) as WsAppSyncEvent;
            appSyncSubscriptionUpdate(
              db,
              eventData,
              addToAudioQueue,
              handleUnreadItem,
              handleReadItemEvent,
              client,
            );
          } else {
            console.log(
              "Account Feed Subscription Event called with no data",
              data,
            );
          }
        },
        (e: any) => {
          console.error("Account Feed Subscription Error", e);
        },
      );
      subscription?.unsubscribe();
      subscription = newSub;
    },
    [
      pubSub,
      db,
      addToAudioQueue,
      handleUnreadItem,
      handleReadItemEvent,
      client,
    ],
  );

  useEffect(() => {
    const validateUser = async () => {
      console.log("Validating User", { user });
      if (user?.session) {
        subscribeToAppSync([
          user?.session?.credentials[0]?.CredentialScopes[0]?.accountId,
        ]);
        return;
      }
      try {
        const userInfoResponse = await client.getUserInfo();
        if (userInfoResponse?.workspaces?.length > 0) {
          await createManyWorkspace(db, userInfoResponse?.workspaces || []);
        }
        if (userInfoResponse?.workspaceMemberships?.length > 0) {
          await createManyWorkspaceMembership(
            db,
            userInfoResponse?.workspaceMemberships || [],
          );
        }
        if (userInfoResponse?.accounts?.length > 0) {
          await createManyWsAccount(db, userInfoResponse?.accounts || []);
        }
        setUser((prev) =>
          JSON.stringify(prev) !== JSON.stringify(userInfoResponse)
            ? userInfoResponse
            : prev,
        );
        const sessionStorageValue =
          window?.sessionStorage?.getItem(LANGUAGE_SESSION_KEY);
        if (sessionStorageValue) {
          console.log("FOUND SESSION STORAGE VALUE", sessionStorageValue);
        } else {
          console.log("NO SESSION STORAGE VALUE");
        }
        const account = await upsertMyAccount(db, userInfoResponse);
        if (account) {
          if (!account?.preferredLanguage) {
            await db.account.update({
              where: {
                id: account.id,
              },
              data: {
                preferredLanguage: sessionStorageValue || "none",
              },
            });
          }
          subscribeToAppSync([account.id]);
          ampli.client.setUserId(account.id);
          Sentry.setUser({ accountId: account.id });
          ampli.authSuccess({ "Auth0 App": "SMS" });
          ldClient
            .identify({
              kind: "user",
              key: account.id,
              name: account?.name,
              email: account?.email,
              sbAccount: account?.email?.includes("@storyboard.fm"),
            })
            .catch((e) => {
              console.error(e);
            });
          if (window.dataLayer) {
            window.dataLayer.push({
              event: {
                name: "authSuccess",
                value: "Auth0 App: SMS",
              },
            });
          }
        }
      } catch (e) {
        console.log({ e });
        return Promise.reject(e);
      }
    };
    validateUser();
  }, [JSON.stringify(user), subscribeToAppSync]);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    null,
  );
  const { allComplete, activeWorkspaceId, bootstrapPaginatedApplication } =
    BootstrapApplication();

  let localWorkspaceId = null;
  useEffect(() => {
    if (
      selectedWorkspaceId &&
      workspaceId &&
      selectedWorkspaceId !== workspaceId
    ) {
      localWorkspaceId = workspaceId;
    }

    if (!workspaceId && !selectedWorkspaceId && user?.workspaces?.length > 0) {
      localWorkspaceId = user.workspaces[0].id;
    }

    if (workspaceId && !selectedWorkspaceId) {
      localWorkspaceId = workspaceId;
    }

    if (localWorkspaceId) {
      bootstrapPaginatedApplication({
        client,
        db,
        user,
        workspaceId: localWorkspaceId,
        forceBootstrap: true, //params.workspaceId !== localWorkspaceId,
      }).then(() => {
        setSelectedWorkspaceId(() => localWorkspaceId);
        localWorkspaceId = null;
      });
    } else if (user?.session?.credentials?.[0]?.id && !localWorkspaceId) {
      client
        .bootstrapWorkspaces()
        .then((data) => setBootstrapComplete(() => true));
    }
  }, [
    user?.session?.credentials?.[0]?.id,
    localWorkspaceId,
    workspaceId,
    selectedWorkspaceId,
  ]);

  useEffect(() => {
    setBootstrapComplete(() => allComplete);
  }, [allComplete]);

  const { results: currentFeed } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.feed.liveUnique({
      where: {
        id: currentFeedId,
      },
    });
  }, [currentFeedId]);

  const { results: myMemberships } = useLiveQuery(() => {
    if (!myAccount?.id) return;
    return db.workspace_membership.liveMany({
      where: {
        accountId: myAccount?.id,
        status: "active",
      },
    });
  }, [myAccount?.id]);

  const { results: myFeedPermissions } = useLiveQuery(() => {
    if (!myMemberships) return;
    return db.permission.liveMany({
      where: {
        enabled: 1,
        feedId: currentFeedId,
        workspace_membershipId: {
          in: myMemberships?.map((m) => m.id) || [],
        },
      },
    });
  }, [myMemberships, currentFeedId]);

  const isCurrentFeedAdmin = useMemo(
    () => myFeedPermissions?.some((p) => p.name === "admin"),
    [myFeedPermissions],
  );
  const haveWritePermission = useMemo(
    () => myFeedPermissions?.some((p) => p.name === "write"),
    [myFeedPermissions],
  );

  const { results: feedAccounts } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.liveRaw({
      sql: `SELECT
						a.*,
						wshf.enabled as hands_free_enabled,
						wshf.timestamp as hands_free_timestamp
				FROM
					permission as p
				JOIN workspace_membership as wm
					ON wm.id = p.workspace_membershipId
				JOIN account as a 
					ON a.id = wm.accountId
				LEFT OUTER JOIN ws_hands_free_status as wshf ON wshf.id = p.workspace_membershipId
				WHERE
					p.feedId = ?
				AND
					p.enabled = 1
				AND
					wm.status = 'active'
				GROUP BY a.id
				ORDER BY LOWER(a.name) asc
				`,
      args: [currentFeedId],
    });
  }, [currentFeedId]);

  const currentFeedAccounts = useMemo(
    () => accountInfo(feedAccounts),
    [feedAccounts],
  );

  const { results: currentFeedPendingInvites } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.liveRaw({
      sql: `SELECT
						dwi.*
				FROM
						permission as p
				JOIN direct_ws_invitation as dwi
					ON dwi.workspaceMembershipId = p.workspace_membershipId
				JOIN workspace_membership as wm
					ON wm.id = dwi.workspaceMembershipId
				WHERE
						p.feedId = ?
				AND
						p.enabled = 1
				AND
						dwi.claimedBy is null
				AND
						wm.status = 'active'
				GROUP BY
				p.workspace_membershipId`,
      args: [currentFeedId],
    });
  }, [currentFeedId]);

  const loadWorkspaceDetails = React.useCallback(
    async (workspaceId: string) => {
      try {
        const membership = await db.workspace_membership.findFirst({
          where: {
            accountId: myAccount?.id,
            workspaceId,
            status: "active",
          },
        });
        if (
          !myAccount?.id ||
          !workspaceId ||
          membership?.role === "limitedMember"
        )
          return;
        await client.getWorkspaceInvitations(workspaceId).then((resp) => {
          console.log("Workspace details response", { resp });
          for (const membership of resp?.workspaceMemberships || []) {
            upsertWorkspaceMembership(db, membership);
          }
          for (const account of resp?.accounts || []) {
            upsertWsAccount(db, account);
          }
          for (const directInvitation of resp?.directInvitations || []) {
            upsertDirectWsInvitation(db, directInvitation);
          }
        });
      } catch (e) {
        console.error("Error loading workspace details", e);
      }
    },
    [myAccount?.id],
  );

  const loadWorkspaceWorkflowItems = React.useCallback(
    async (workspaceId: string) => {
      if (!myAccount?.id) return;
      await client.getWorkspaceWorkflowItems(workspaceId).then((resp) => {
        console.log("Workspace workflow items response", { resp });
        for (const workflowItem of resp?.workflowItems || []) {
          upsertWsDraft(db, workflowItem);
        }
        // load associated content of the workflow items
        for (const i of resp?.audioEncodings || []) {
          upsertWsAudioEncoding(db, i);
        }
        for (const i of resp?.callRecords || []) {
          upsertWsCallRecord(db, i);
        }
        for (const i of resp?.files || []) {
          upsertWsFile(db, i);
        }
        for (const i of resp?.links || []) {
          upsertWsLink(db, i);
        }
        for (const i of resp?.transcriptions || []) {
          upsertWsTranscription(db, i);
        }
        for (const i of resp?.displayArtifacts || []) {
          upsertWsDisplayArtifact(db, i);
        }
      });

      await client.getScheduledBroadcasts(workspaceId).then((resp) => {
        console.log("Workspace scheduled broadcasts response", { resp });
        for (const scheduleTrigger of resp?.scheduleTriggers || []) {
          upsertWsScheduleTrigger(db, scheduleTrigger);
        }
        for (const broadcastAction of resp?.broadcastActions || []) {
          upsertWsBroadcastAction(db, broadcastAction);
        }
        for (const broadcastRecipient of resp?.broadcastRecipients || []) {
          upsertWsBroadcastRecipient(db, broadcastRecipient);
        }
      });
    },
    [client, db, myAccount?.id],
  );

  const fetchWorkspaceMembership = async (
    accountId: string,
    workspaceId: string,
  ) => {
    const workspaceMembership = await db.workspace_membership.findFirst({
      where: {
        accountId,
        workspaceId,
        status: "active",
      },
    });
    if (!workspaceMembership) {
      return Promise.reject("Invalid Workspace");
    }
    return workspaceMembership;
  };

  const checkFeedPermissions = async ({
    workspaceMembershipId,
    feedId,
    isUserOnPage,
    workspaceId,
  }: {
    workspaceMembershipId: string;
    workspaceId: string;
    feedId: string;
    isUserOnPage: boolean;
  }) => {
    const feed = await db.feed.findUnique({ where: { id: feedId } });
    if (!feed) {
      return Promise.reject("Invalid Feed");
    }
    const permissions = await db.permission.findMany({
      where: {
        feedId,
        enabled: 1,
        workspace_membershipId: { in: [workspaceMembershipId] },
      },
    });
    const workspaceMember = await db.workspace_membership.findFirst({
      where: {
        AND: [{ id: workspaceMembershipId }, { workspaceId }],
      },
    });
    const limitedMember =
      workspaceMember?.role ===
      availableWorkspaceRoles.get("limitedMember")?.role;

    if (permissions?.length === 0) {
      if (isUserOnPage) {
        // if the user is on a feed, but had their permissions revoked, redirect them to the workspace
        return Promise.reject("redirect");
      }
      // if the feed is public and the user is a member of the workspace, join the feed
      if (!limitedMember && feed?.isPrivate === 0 && workspaceMember) {
        return await joinPublicChannel(workspaceId, feedId);
      }
      return Promise.reject("No Feed Permissions");
    }
    return true;
  };

  const listFeedPermissions = React.useCallback(
    async (
      workspaceMembershipId: string,
      feedId: string,
    ): Promise<Array<Permission>> => {
      try {
        return await db.permission.findMany({
          where: {
            feedId,
            workspace_membershipId: { in: [workspaceMembershipId] },
          },
        });
      } catch (e) {
        return [];
      }
    },
    [db],
  );

  const joinPublicChannel = async (
    workspaceId: string,
    feedId: string,
  ): Promise<boolean> => {
    const permissions = await client.joinPublicFeed(workspaceId, feedId);
    for (const p of permissions) {
      upsertWsPermission(db, p);
    }
    // after joining the feed, download the feed items
    await downloadPaginatedBootstrapFeedItems(client, db, workspaceId);
    return true;
  };

  const leavePublicChannel = async (
    workspaceId: string,
    feedId: string,
    membershipId: string,
  ): Promise<boolean> => {
    await client.unSubscribeFromWorkspaceFeed(workspaceId, feedId);
    await db.permission.deleteMany({
      where: {
        feedId,
        workspace_membershipId: { in: [membershipId] },
      },
    });
    return true;
  };

  const leaveFeedGroup = async ({
    workspaceId,
    feedGroupId,
  }: { workspaceId: string; feedGroupId: string }): Promise<boolean> => {
    const response = await client.leaveFeedGroup({ workspaceId, feedGroupId });
    const permissions = response?.permissions || [];
    await upsertManyWsPermission(db, permissions, adapter);
    return true;
  };

  const joinFeedGroup = async ({
    workspaceId,
    feedGroupId,
  }: { workspaceId: string; feedGroupId: string }): Promise<boolean> => {
    const response = await client.joinFeedGroup({ workspaceId, feedGroupId });
    const permissions = response?.permissions || [];
    await upsertManyWsPermission(db, permissions, adapter);
    await downloadPaginatedBootstrapFeedItems(client, db, workspaceId);
    return true;
  };

  const fetchWorkspaceHandsFreeStatus = async (): Promise<void> => {
    const allWorkspaceMemberships = await db.workspace_membership.findMany({
      where: {
        AND: [{ status: "active" }, { workspaceId }],
      },
    });
    const handsFreeStatusResponse = await client.getHandsFreeStatus(
      workspaceId,
      {
        membershipIds: allWorkspaceMemberships.map((m) => m.id),
      },
    );

    if (!handsFreeStatusResponse?.data) return;

    const { data } = handsFreeStatusResponse;

    const checkTimestampAgainstDate = (timestamp: string): boolean => {
      const now = new Date();
      const nowGmt = now.toUTCString();
      const gmt = new Date(timestamp).toUTCString();
      const nowTime = new Date(nowGmt).getTime();
      const timestampTime = new Date(gmt).getTime();
      const diff = nowTime - timestampTime;
      const millisecondsInMinute: number = 60000;
      const minutes: number = 11;
      const threshold: number = minutes * millisecondsInMinute;
      return diff >= threshold;
    };

    const mappedResponse: Array<{
      id: string;
      enabled: number;
      timestamp: string;
    }> = data
      ?.filter((item) => item?.statuses[0]?.timestamp)
      ?.map((item) => {
        const staleTimestamp = checkTimestampAgainstDate(
          item.statuses[0].timestamp,
        );
        const enabled = item.statuses[0].enabled ? 1 : 0;
        return {
          id: item.membershipId,
          enabled: staleTimestamp === true ? 0 : enabled,
          timestamp: item.statuses[0].timestamp,
        };
      })
      ?.sort((a, b) => b.enabled - a.enabled);

    if (mappedResponse?.length > 0) {
      for (const status of mappedResponse) {
        upsertWsHandsFreeStatus(db, status);
      }
    }
  };

  const fetchMyActiveFeeds = (
    workspaceId: string,
    workspaceMembershipId: string,
  ) => {
    if (!workspaceId || !workspaceMembershipId) return;
    const { results: myActiveFeeds } = useLiveQuery(() => {
      return db.liveRaw({
        sql: `
            SELECT
                feed.id,
                feed.title,
                feed.workspaceId,
                feed.updatedAt,
                permission.name,
                MAX(
                    MAX(coalesce(item.createdAt, '0')),
                    coalesce(feed.updatedAt, '0'),
                    coalesce(feed.latestActivity, '0')
                ) as latestActivity
            FROM
                feed
                    JOIN permission ON permission.feedId = feed.id
                    JOIN workspace_membership on workspace_membership.id = permission.workspace_membershipId
                    JOIN account ON account.id = workspace_membership.accountId
                    LEFT JOIN item ON feed.id = item.feedId
            WHERE
                feed.workspaceId = ?
              AND
                feed.id IN ( SELECT permission.feedId FROM permission WHERE permission.workspace_membershipId = ? AND permission.name = 'read' AND enabled = true)
              AND
                feed.isDm = 0
              AND
                permission.workspace_membershipId = ?
              AND
                permission.enabled = 1
            GROUP BY feed.id
            ORDER BY latestActivity DESC
        `,
        args: [workspaceId, workspaceMembershipId, workspaceMembershipId],
      });
    }, [workspaceId, workspaceMembershipId]);
    return myActiveFeeds;
  };

  const fetchGeoCoords = async (
    workspaceId: string,
    membershipIds: string[],
  ): Promise<{ lat: number; lng: number } | null> => {
    const { data } = await client.getLocations(workspaceId, { membershipIds });
    return data[0]?.locations[0]?.latitude
      ? {
          lat: data[0]?.locations[0]?.latitude,
          lng: data[0]?.locations[0]?.longitude,
        }
      : null;
  };

  const fetchSingleFeed = (feedId: string): Feed | null => {
    const { results: feed } = useLiveQuery(() => {
      if (!feedId) return;
      return db.feed.liveFirst({
        where: {
          id: feedId,
        },
      });
    }, [feedId]);
    return feed?.id ? feed : null;
  };

  const myCurrentWorkspaceMembership = myMemberships?.find(
    (m) => m?.workspaceId === currentWorkspaceId,
  );
  const myCurrentWorkspaceRole = myCurrentWorkspaceMembership?.role;

  const isWorkspaceAdmin = (workspaceId: string, accountId: string) => {
    return myCurrentWorkspaceRole === "admin";
  };

  const isWorkspaceLimitedMember = (workspaceId: string, accountId: string) => {
    return myCurrentWorkspaceRole === "limitedMember";
  };

  const [deviceRegistrationId] = useLocalStorage("deviceRegistrationId", "");

  useEffect(() => {
    const f = async () => {
      try {
        let deviceId = deviceRegistrationId;
        if (!deviceId) {
          deviceId = cuid();
        }
        const newDeviceReg = {
          id: deviceId,
          surface: "web",
        } as DeviceRegistration;
        const deviceRegistration = await client.registerDevice({
          deviceRegistration: newDeviceReg,
        });
        if (deviceRegistration?.id) {
          // save to local storage
          await upsertDeviceRegistration(db, deviceRegistration);
        }
      } catch (error) {
        console.error("Error registering device", error);
      }
    };
    f();
  }, []);

  const applicationContext = {
    handsFreeEnabled: handsFreeEnabledForWorkspace(currentWorkspaceId),
    selectedWorkspaceId: currentWorkspaceId,
    workspaces:
      workspaces?.map((w) => {
        return {
          handsFreeEnabled: handsFreeEnabledForWorkspace(w?.id) || false,
          workspaceId: w?.id || "",
          feedSelected: currentFeed?.workspaceId === w?.id ? currentFeedId : "",
          languageSelected: preferredLanguage,
        };
      }) || [],
  } as ApplicationContext;

  const deviceContext = {
    surface: "web",
    surfaceContext: window?.navigator?.userAgent,
    surfaceBuild: buildVersion(),
    notificationsEnabled: Notification?.permission === "granted",
    internetConnectionStatus: useNetworkState()?.online ? "online" : "offline",
    deviceLanguage: window?.navigator?.language || preferredLanguage,
    deviceRegistrationId: deviceRegistrationId,
  } as DeviceContext;

  const dataState: DataState = {
    appContext: applicationContext,
    availableWorkspaceRoles,
    getWorkspaceRoleLabel,
    joinPublicChannel,
    leavePublicChannel,
    joinFeedGroup,
    leaveFeedGroup,
    myAccount,
    myCurrentWorkspaceRole,
    myCurrentWorkspaceMembership,
    isWorkspaceAdmin,
    isWorkspaceLimitedMember,
    myAccountId: myAccount?.id,
    currentFeedId,
    currentWorkspaceId,
    currentFeed,
    currentFeedAccounts,
    currentFeedPendingInvites,
    deviceContext,
    isCurrentFeedAdmin,
    haveWritePermission,
    workspaceMemberships,
    bootstrapComplete,
    preferredLanguage,
    setPreferredLanguage: setPreferredLanguage,
    fetchWorkspaceMembership,
    checkFeedPermissions,
    listFeedPermissions,
    fetchMyActiveFeeds,
    fetchSingleFeed,
    fetchWorkspaceHandsFreeStatus,
    fetchGeoCoords,
    loadFeed: (feedId: string) => {
      return initialFeedLoad(client, db, feedId);
    },
    loadNextFeedItemPage: async (feedId: string) => {
      return getNextFeedItemPage(client, db, feedId);
    },
    loadWorkspaceDetails,
    loadWorkspaceWorkflowItems,
    workspaces,
  };
  return (
    <DataContext.Provider value={dataState}>{children}</DataContext.Provider>
  );
};
export default DataProvider;
